<template>
  <div>
    <b-modal :no-close-on-backdrop="true"
      id="save-student-support-modal"
      ref="save-student-support-modal"
      centered
      hide-footer
      @hidden="resetSaveStudentSupportModal"
    >
      <validation-observer ref="saveStudentSupportForm">
        <b-form class="auth-register-form mt-2">
          <div>
            <b-form-group label="First name" label-for="register-firstname">
              <validation-provider
                #default="{ errors }"
                name="Firstname"
                rules="required"
              >
                <b-form-input
                  id="register-firstname"
                  name="register-firstname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="First name"
                  v-model="student_support_form.user_firstname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Last name" label-for="register-lastname">
              <validation-provider
                #default="{ errors }"
                name="Lastname"
                rules="required"
              >
                <b-form-input
                  id="register-lastname"
                  name="register-lastname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Last Name"
                  v-model="student_support_form.user_lastname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Phone number" label-for="register-mobile">
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                rules="required"
              >
                <b-form-input
                  id="register-mobile"
                  type="number"
                  name="register-mobile"
                  :state="errors.length > 0 ? false : null"
                  placeholder="+91XXXXXXXXXX"
                  v-model="student_support_form.user_mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Email" label-for="register-email">
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                rules="required"
              >
                <b-form-input
                  id="register-email"
                  :state="errors.length > 0 ? false : null"
                  name="register-email"
                  v-model="student_support_form.user_email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Password" label-for="register-password">
              <!-- <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              > -->
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="register-password"
                  class="form-control-merge rp_padding"
                  name="register-password"
                  :type="passwordFieldType"
                  placeholder="············"
                  v-model="student_support_form.user_password"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <!-- </validation-provider> -->
            </b-form-group>
            <b-form-group label="Auto Assign" label-for="auto-assign-switch">
              <b-form-checkbox
                id="auto-assign-switch"
                switch
                value="Y"
                unchecked-value="N"
                v-model="student_support_form.auto_assign"
              />
            </b-form-group>

            <b-form-group label="Students" label-for="student-select">
              <v-select
                id="student-select"
                multiple
                :reduce="(s) => s.id"
                label="name"
                v-model="student_support_form.students"
                :options="students_list"
                :closeOnSelect="false"
                :selectable="(s) => s.selectable"
              >
              </v-select>
            </b-form-group>
          </div>
          <div class="text-right">
            <b-button @click="closeModal" variant="outline-danger mr-2">
              Close
            </b-button>
            <b-button
              variant="outline-primary"
              type="submit"
              @click.prevent="saveStudentSupport"
            >
              Submit
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-card>
      <b-col class="m-2 w-100">
        <b-row cols="12 mx-3 mb-2">
          <b-col cols="8">
            <label>Search</label>
            <b-form-input
              v-model="filters.search"
              class="d-inline-block mr-1"
              placeholder="Type Here..."
              @input="onSearch"
            />
          </b-col>

          <!-- Status -->
          <b-col
            cols="2"
            class="d-flex align-items-start justify-content-end flex-column mb-1 mb-md-0"
          >
            <label>Status</label>
            <v-select
              v-model="filters.status"
              :options="[
                { label: 'All', value: null },
                { label: 'Active', value: 'Y' },
                { label: 'Inactive', value: 'N' },
              ]"
              :clearable="false"
              item-text="label"
              :reduce="(item) => item.value"
              class="per-page-selector d-inline-block w-100"
              @input="onSearch"
            />
          </b-col>

          <b-col
            cols="2"
            class="d-flex align-items-start justify-content-center flex-column mb-1 mb-md-0 p-0"
          >
            <label>Per Page</label>
            <v-select
              v-model="pagination.perPage"
              :options="pagination.perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block w-100"
            />
          </b-col>
        </b-row>

        <b-row cols="12 mx-3 mb-2 d-flex justify-content-end">
          <b-col
            md="2"
            class="d-flex align-items-end justify-content-center mb-1 mb-md-0 pr-2"
          >
            <button
              class="btn btn-primary mr-1 w-100"
              @click="getStudentSupportList()"
            >
              Search
            </button>
            <button class="btn btn-primary w-100" @click="resetFilters">
              Reset
            </button>
          </b-col>
        </b-row>
      </b-col>
    </b-card>
    <b-card class="mb-0">
      <div class="m-2">
        <b-button variant="outline-primary" v-b-modal.save-student-support-modal
          >Add Student Support</b-button
        >
      </div>

      <!-- Student Support List -->
      <b-table
        id="StudentsSupportListTableId"
        ref="refStudentSupportsListTable"
        class="position-relative"
        :items="studentSupportsList"
        responsive
        :fields="tableColumns"
        primary-key="user_id"
        show-empty
        empty-text="No matching records found"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
      >
        <template #cell(user_name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="FILESURL + data.item.user_profile_image"
              />
            </template>
            <p>
              {{ data.item.user_name }}
            </p>
          </b-media>
        </template>

        <template #cell(user_mobile)="data">
          <div style="width: max-content">
            {{ data.value }}
          </div>
        </template>

        <template #cell(user_is_active)="data">
          <b-form-checkbox
            switch
            v-model="data.value"
            unchecked-value="N"
            value="Y"
            @input="statusChange($event, data.item)"
          />
        </template>
        <template #cell(action)="data">
          <b-tooltip
            triggers="hover"
            :target="`edit-${data.item.user_id}`"
            title="Edit"
            :delay="{ show: 1000 }"
          />
          <feather-icon
            :id="`edit-${data.item.user_id}`"
            style="cursor: pointer"
            icon="EditIcon"
            size="18"
            @click="openSaveStudentSupportModal(data.item)"
          />
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ studentSupportsList.length }} entries
            </span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              aria-controls="StudentsSupportListTableId"
              v-model="pagination.currentPage"
              :total-rows="studentSupportsList.length"
              :per-page="pagination.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import vSelect from "vue-select";
import { FILESURL } from "@/config";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import AgentServices from "@/apiServices/AgentServices";
import store from "@/store";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BTooltip,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormSelectOption,
    flatPickr,
  },
  directives: {
    // "b-tooltip": VBTooltip,
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    user_type() {
      return store.getters["user/getUserDetails"].user_type;
    },
    students_list() {
      return this.students.map((s) => {
        let name = `${s.user_id} ${s.user_firstname} ${s.user_lastname}`;

        let selectable = true;
        if (
          s.ss_user_id &&
          s.ss_user_id !== this.student_support_form.user_id
        ) {
          name += ` - ${s.ss_firstname} ${s.ss_lastname}`;
          selectable = false;
        }

        return {
          profile: s.user_profile_image,
          name,
          id: s.user_id,
          selectable,
        };
      });
    },
  },
  data() {
    return {
      FILESURL,
      studentSupportsList: [],
      tableColumns: [
        { key: "user_id", label: "Id", sortable: false },
        { key: "user_name", label: "Name", sortable: false },
        { key: "user_mobile", label: "Phone", sortable: false },
        { key: "user_email", label: "Email", sortable: false },
        { key: "user_is_active", label: "Status", sortable: false },
        { key: "action", label: "Action", sortable: false },
      ],
      pagination: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
      },
      student_support_form: {
        user_id: null,
        user_firstname: null,
        user_lastname: null,
        user_email: null,
        user_mobile: null,
        user_password: null,
        auto_assign: "Y",
        user_is_active: "Y",
        students: [],
      },
      filters: {
        search: null,
        status: null,
      },
      students: [],
      passwordFieldType: "password",
      required,
      email,
      debounce: null,
    };
  },
  methods: {
    async openSaveStudentSupportModal(item) {
      try {
        const res = await AgentServices.getSingleStudentSupport(item.user_id);

        if (res.data.status) {
          this.student_support_form = {
            ...this.student_support_form,
            ...res.data.data,
          };
          this.$refs["save-student-support-modal"].show();
        }
      } catch (error) {
        console.error(`Error in openSaveStudentSupportModal `, error);
      }
    },

    async statusChange(e, item) {
      try {
        const res = await AgentServices.saveStudentSupport({
          ...item,
          user_is_active: e,
        });

        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Status Saved",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
        console.error(`Error in statusChange `, error);
      }
    },

    async getStudents() {
      try {
        const res = await AgentServices.getAllStudentsForStudentSupport();
        if (res.data.status) {
          this.students = res.data.data;
        }
      } catch (error) {
        console.error(`Error in getStudents `, error);
      }
    },

    async getStudentSupportList() {
      try {
        const response = await AgentServices.getAllStudentSupportList({
          ...this.filters,
        });
        if (response.data.status) {
          this.studentSupportsList = response.data.data;
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Error fetching Student Support",
              icon: "EditIcon",
              variant: "primary",
            },
          });
        }
      } catch (error) {
        console.error("Error getting assigned Student Support ", error);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error fetching Student Support",
            icon: "EditIcon",
            variant: "primary",
          },
        });
      }
    },

    onSearch() {
      if (this.debounce) {
        clearTimeout(this.debounce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.$nextTick(() => {
          this.getStudentSupportList();
        });
      }, 1000);
    },

    resetFilters() {
      this.filters = {
        search: null,
        status: null,
      };
    },

    resetSaveStudentSupportModal() {
      this.student_support_form = {
        user_id: null,
        user_firstname: null,
        user_lastname: null,
        user_email: null,
        user_mobile: null,
        user_password: null,
        auto_assign: "Y",
        user_is_active: "Y",
      };
    },

    async saveStudentSupport(e) {
      e.preventDefault();
      this.$refs.saveStudentSupportForm.validate().then(async (success) => {
        if (success) {
          try {
            const response = await AgentServices.saveStudentSupport(
              this.student_support_form
            );

            if (response.data.status) {
              this.$refs["save-student-support-modal"].hide();
              this.getStudentSupportList();
              this.getStudents();
            } else {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: response.data.message || 'Failed',
                  icon: 'EditIcon',
                  variant: 'primary',
                }
              });
            }
          } catch (err) {
            console.error("Error in Adding Student ", err);
          }
        }
      });
    },

    closeModal() {
      this.$refs["save-student-support-modal"].hide();
    },

    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
  beforeMount() {
    this.getStudents();
    this.getStudentSupportList();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
